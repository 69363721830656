/* .load {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 1);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tilo {
    font-size: 3rem;
    text-align: center;
    opacity: 0.1;
    margin: 0;
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
    z-index: 1;
}

.progress {
    background-color: transparent;
    height: 100%;
    width: 100%;
    position: absolute;

}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
} */


/* استایل‌های صفحه لودینگ */
.loading-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #ffffff, #ffffff);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 1000;
  }


  
  .loading-circle {
    width: 100px;
    height: 100px;
    border: 5px solid #000000;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    animation: pulse 2s infinite ease-in-out;
  }
  
  @keyframes pulse {
    0% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
    }
    50% {
      box-shadow: 0 0 30px rgba(255, 255, 255, 1);
    }
    100% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
    }
  }
  
  .loading-text {
    color: #fff;
    font-size: 2rem;
    font-weight: bold;
    margin-top: 20px;
    letter-spacing: 2px;
    text-transform: uppercase;
    animation: fadeInOut 2s infinite ease-in-out;
  }
  
  @keyframes fadeInOut {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }
  